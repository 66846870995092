<template>
  <div class="col-md-12" v-if="Object.keys(requisition).length !== 0">
    <div class="card">
      <div class="card-body">
        <a-steps progress-dot :current="current" size="small">
          <a-step title="Product Request" :description="requisition.requisition_statuses.filter(e => e.status === 'STORE_IN_CHARGE_PENDING').length > 0 ? customDate(requisition.requisition_statuses.find(e => e.status === 'STORE_IN_CHARGE_PENDING').created_at) : ''" />
          <a-step title="Main Warehouse"  :description="requisition.requisition_statuses.filter(e => e.status === 'STORE_IN_CHARGE_APPROVED').length > 0 ? customDate(requisition.requisition_statuses.find(e => e.status === 'STORE_IN_CHARGE_APPROVED').created_at) : ''"  />
          <a-step title="Admin"  :description="requisition.requisition_statuses.filter(e => e.status === 'ADMIN_APPROVED').length > 0 ? customDate(requisition.requisition_statuses.find(e => e.status === 'ADMIN_APPROVED').created_at) : ''" />
          <a-step title="Product Transfered"  :description="requisition.requisition_statuses.filter(e => e.status === 'REQUISITION_TRANSFER_COMPLETED').length > 0 ? customDate(requisition.requisition_statuses.find(e => e.status === 'REQUISITION_TRANSFER_COMPLETED').created_at) : ''" />
          <a-step title="Tejgaon Dispatch"  v-if="requisition.hub.hub_zone_id == 2"  :description="requisition.requisition_statuses.filter(e => e.status === 'REQUISITION_TRANSFER_FROM_TEJGAON_COMPLETED').length > 0 ? customDate(requisition.requisition_statuses.find(e => e.status === 'REQUISITION_TRANSFER_FROM_TEJGAON_COMPLETED').created_at) : ''" />
          <a-step title="Hub Received"  :description="requisition.requisition_statuses.filter(e => e.status === 'REQUISITION_TRANSFER_RECEIVED').length > 0 ? customDate(requisition.requisition_statuses.find(e => e.status === 'REQUISITION_TRANSFER_RECEIVED').created_at) : ''" />
        </a-steps>
      </div>
    </div>
  </div>
</template>

<script>

import moment from 'moment'

export default {
  name: 'requisition_header_status',
  props: ['current', 'requisition'],
  data() {
    return {}
  },
  methods: {
    customDate(date) {
      return moment(date).format('LLL')
    },
  },
}
</script>

<style scoped>
.ant-steps-item-description {
  margin-top: -5px !important;
}
</style>
